exports.components = {
  "component---src-page-templates-service-jsx": () => import("./../../../src/page-templates/service.jsx" /* webpackChunkName: "component---src-page-templates-service-jsx" */),
  "component---src-page-templates-static-jsx": () => import("./../../../src/page-templates/static.jsx" /* webpackChunkName: "component---src-page-templates-static-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-autorizations-jsx": () => import("./../../../src/pages/autorizations.jsx" /* webpackChunkName: "component---src-pages-autorizations-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sales-jsx": () => import("./../../../src/pages/sales.jsx" /* webpackChunkName: "component---src-pages-sales-jsx" */),
  "component---src-pages-sales-parts-jsx": () => import("./../../../src/pages/sales_parts.jsx" /* webpackChunkName: "component---src-pages-sales-parts-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

