const update = () => {
  window.proto && window.proto.demoInit.apply(this, [window.jQuery]);
  window.proto && window.proto.themeInit.apply(this, [window.jQuery]);
};

export const onRouteUpdate = () => {
  update();
};

export const onInitialClientRender = () => {
  update();
};
